import { Container } from '@material-ui/core';
import React from 'react';

import { Layout, SEO, TermsCard } from '../components';

export default function Legal(props) {
  return (
    <Layout {...props}>
      <SEO title="Terms" pathname={props.location.pathname} />
      <Container maxWidth="md">
        <TermsCard />
      </Container>
    </Layout>
  );
}
